export const jobObjOne = {

    title: "Attochron Careers",
    target: "_blank",
    contact: "jimolson@attochron.com",

    jobHeader1: "Photonics Telecommunications Engineer - ",
    jobButton1: "See Job Description",
    jobLink1: require('../../docs/2024 Attochron Photonics Telecoms Engineer Job Description - 090524.pdf'),

    jobHeader2: "Technical Field Engineer - ",
    jobButton2: "See Job Description",
    jobLink2: require('../../docs/2024 Attochron Field Technician Job Description - 090524.pdf'),

}