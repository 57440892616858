import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const TeamContainer = styled.div`
    background: #d3d3d3;
    padding-top: 100px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`

export const StoryTextWrapper = styled.div`
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
`

export const StoryHeader = styled.h1`
    display: flex;
	z-index: 1;
	max-width: 220px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 2.3rem;
	font-weight: bold;

    @media screen and (max-width: 480px) {
		font-size: 1.8rem;
		text-align: center;
	}
`

export const StorySubheader = styled.h1`
    display: flex;
	width: 100%;
    margin: 0 auto;
    padding-bottom: 3rem;
    max-width: 800px;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	font-weight: bold;
    text-align: center;

    @media screen and (max-width: 769px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
	}

    @media screen and (max-width: 480px) {
        font-size: 1.2rem;
        padding-bottom: 2rem;
	}
`

export const StoryButton = styled(LinkR)`
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    background: transparent;
    text-decoration: none;
    color: #000000;
    border: 4px solid #000000;
    border-radius: 18px;
    padding: 8px 24px 8px 24px;
    cursor: pointer;
    width: 200px;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
		background: #f0f0f0;
    }
`

export const TitleWrapper = styled.div`
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid black;

    @media screen and (max-width: 480px) {
		max-width: 270px;
	}

    @media screen and (max-width: 370px) {
        max-width: 200px;
        margin-top: 2rem;
    }
`

export const Title = styled.h1`
    font-size: 2.3rem;
    font-weight: bold;

    @media screen and (max-width: 480px) {
		font-size: 1.8rem;
		text-align: center;
	}

    @media screen and (max-width: 370px) {
        font-size: 1.5rem;
    }
`

export const TeamTileWrapper = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 9rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 250px 250px 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 769px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 250px 250px 250px 250px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px 200px 200px 200px 200px;
    }

    @media screen and (max-width: 370px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px 150px 150px 150px 150px;
    }
`

export const TeamR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR1C3 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR2C1 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR2C2 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 3;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR2C3 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR2C4 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 4;
    grid-column-end: 4;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR3C1 = styled.div`
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 3;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR3C2 = styled.div`
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR3C3 = styled.div`
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 5;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamR3C4 = styled.div`
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 4;
    grid-column-end: 4;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    margin-top: 5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 3;
        grid-column-end: 3;
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 5;
        grid-row-end: 5;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const TeamIMG = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const TeamTileCover = styled.div`
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    &:hover{
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.8);
    }
`

export const TeamTextWrapper = styled.div`
    display: block;
    position: relative;
    height: 100%;
    transition: 0.3s;
    transform: translateY(13.7rem);
    bottom: 0;
    padding: .2rem;
    width: 100%;
    background-color: rgba(240, 240, 240, 0.9);

    ${TeamTileCover}:hover & {
        transition: 0.4s;
        transform: translateY(0%);
    }

    @media screen and (max-width: 1025px) {
        transform: translateY(10.9rem);
    }

    @media screen and (max-width: 480px) {
        transform: translateY(8.5rem);
    }

    @media screen and (max-width: 370px) {
        transform: translateY(6rem);
    }
`

export const TeamName = styled.h1`
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;

    ${TeamTileCover}:hover & {
        margin-top: 2rem;
        font-size: 1.8rem;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;

        ${TeamTileCover}:hover & {
        margin-top: 1rem;
        font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 480px) {

        ${TeamTileCover}:hover & {
        margin-top: 1rem;
        font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 480px) {

        ${TeamTileCover}:hover & {
        margin-top: .5rem;
        font-size: 1rem;
        }
    }
`

export const TeamText = styled.h1`
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 2rem;
    padding-left: .3rem;
    padding-right: .3rem;
    text-align: center;
    display: none;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 370px) {
        font-size: 0.6rem;
    }
`

export const TeamIcon = styled(LinkR)`
    font-size: 3rem;
    font-weight: bold;
    margin-top: 2rem;
    text-align: center;
    display: none;
    text-decoration: none;
    color: #000000;
    max-width: 50px;
    margin-left: auto;
    margin-right: auto;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 480px) {
        margin-top: .8rem;
    }

    @media screen and (max-width: 370px) {
        font-size: 1.5rem;
    }
`

export const ConsTileWrapper1 = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 1.8rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0;
    }
    
    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px;
        max-width: 790px;
    }

    @media screen and (max-width: 370px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px;
        max-width: 790px;
    }
`

export const ConsTileWrapper2 = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: 100%;
    max-width: 950px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0rem;
        max-width: 790px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px 200px;
        margin-top: -1rem;
    }

    @media screen and (max-width: 370px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px;
    }
`

export const ConsR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 769px) {
        margin-right: 3rem;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
        margin-right: auto;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const ConsR1C3 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const ConsR2C1 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`
export const ConsR2C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`
export const ConsR2C3 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const ConsName = styled.h1`
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;

    ${TeamTileCover}:hover & {
        margin-top: 2rem;
        font-size: 1.8rem;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;

        ${TeamTileCover}:hover & {
        margin-top: 1rem;
        font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 480px) {

        ${TeamTileCover}:hover & {
        margin-top: 1rem;
        font-size: 1.1rem;
        }
    }

    @media screen and (max-width: 480px) {

        ${TeamTileCover}:hover & {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        }
    }
`

export const ConsText = styled.h1`
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    padding-left: .3rem;
    padding-right: .3rem;
    text-align: center;
    display: none;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 370px) {
        margin-top: 0.5rem;
        font-size: 0.6rem;
    }
`

export const ConsIcon = styled(LinkR)`
    font-size: 3rem;
    font-weight: bold;
    margin-top: 1.5rem;
    text-align: center;
    display: none;
    text-decoration: none;
    color: #000000;
    width: 50px;
    margin-left: auto;
    margin-right: auto;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 2rem;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        margin-top: .5rem;
    }

    @media screen and (max-width: 370px) {
        font-size: 1.5rem;
    }
`

export const InvestTileWrapper = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-rows: 225px;
    }
`

export const InvestR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 275px;
    height: 275px;

    @media screen and (max-width: 1025px) {
        width: 225px;
        height: 225px;
    }

    @media screen and (max-width: 480px) {
        grid-column-start: 1;
        grid-column-end: 1;
        width: 200px;
        height: 200px;
    }
`

export const InvestTextWrapper = styled.div`
    display: block;
    position: relative;
    height: 100%;
    transition: 0.3s;
    transform: translateY(15.2rem);
    bottom: 0;
    padding: .2rem;
    width: 100%;
    background-color: rgba(240, 240, 240, 0.9);

    ${TeamTileCover}:hover & {
        transition: 0.4s;
        transform: translateY(0%);
    }

    @media screen and (max-width: 1025px) {
        transform: translateY(12.5rem);
    }

    @media screen and (max-width: 480px) {
        transform: translateY(9.5rem);
    }
`

export const LegalTileWrapper = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: 100%;
    max-width: 650px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0;
        max-width: 540px;
    }

    @media screen and (max-width: 769px) {
        max-width: 520px;
    }

    @media screen and (max-width: 480px) {
        grid-template-rows: 200px;
        padding-bottom: 0;
    }

    @media screen and (max-width: 370px) {
        grid-template-rows: 150px;
    }
`

export const LegalR1C1 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const LegalR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvisorTileWrapper = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: 100%;
    max-width: 950px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 250px 250px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px 200px 200px;
    }

    @media screen and (max-width: 370px) {
        grid-template-rows: 150px 150px 150px;
    }
`

export const AdvR1C1 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvR1C3 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvR2C1 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvR2C2 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvR2C3 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    width: 250px;
    height: 250px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 200px;
        margin-top: 0;
    }

    @media screen and (max-width: 480px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 2;
        width: 160px;
        height: 160px;
    }

    @media screen and (max-width: 370px) {
        width: 120px;
        height: 120px;
    }
`

export const AdvName = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;

    ${TeamTileCover}:hover & {
        font-size: 1.6rem;
        margin-top: .5rem;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;

        ${TeamTileCover}:hover & {
        font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 0.9rem;

        ${TeamTileCover}:hover & {
        font-size: 0.9rem;
        }
    }

    @media screen and (max-width: 370px) {
        font-size: 0.7rem;

        ${TeamTileCover}:hover & {
        font-size: 0.7rem;
        }
    }
`

export const AdvName2 = styled.div`
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    display: none;

    ${TeamTileCover}:hover & {
        display: block;
        font-size: 1.4rem;
        margin-top: .5rem;
    }

    @media screen and (max-width: 1025px) {
        font-size: 1rem;

        ${TeamTileCover}:hover & {
        font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;

        ${TeamTileCover}:hover & {
        font-size: 0.8rem;
        }
    }

    @media screen and (max-width: 370px) {
        font-size: 0.6rem;

        ${TeamTileCover}:hover & {
        font-size: 0.6rem;
        }
    }
`

export const AdvText = styled.h1`
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1.5rem;
    padding-left: .3rem;
    padding-right: .3rem;
    text-align: center;
    display: none;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 0.9rem;
        margin-top: 0.8rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 0.7rem;
        margin-top: 0.8rem;
    }

    @media screen and (max-width: 370px) {
        margin-top: 0.5rem;

        ${TeamTileCover}:hover & {
        font-size: 0.5rem;
        }
    }
`

export const AdvIcon = styled(LinkR)`
    font-size: 3rem;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    display: none;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    ${TeamTileCover}:hover & {
        display: block;
    }

    @media screen and (max-width: 1025px) {
        font-size: 2rem;
        margin-top: .3rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 2rem;
        margin-top: 0;
    }

    @media screen and (max-width: 370px) {
        font-size: 1.5rem;
    }
`

export const PartnerTileWrapper = styled.div`
    position: relative;
    padding: 1rem;
    padding-bottom: 6rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px 100px;
    flex-direction: column;

    @media screen and (max-width: 1025px) {
        max-width: 900px;
    }

    @media screen and (max-width: 769px) {
        max-width: 700px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 80px 80px 80px 80px;
    }

    @media screen and (max-width: 370px) {
        padding-bottom: 2rem;
    }
`

export const PartnerR1C1 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR1C2 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 2;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

`

export const PartnerR1C3 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR1C4 = styled.div`
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 4;
    grid-column-end: 4;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR2C1 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 1;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR2C2 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR2C3 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 3;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
    }

    @media screen and (max-width: 370px) {
        width: 140px;
        height: 40px;
    }
`

export const PartnerR2C4 = styled.div`
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 4;
    grid-column-end: 4;
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 100px;
    margin-top: 2.5rem;

    @media screen and (max-width: 1025px) {
        width: 200px;
        height: 80px;
    }

    @media screen and (max-width: 769px) {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px) {
        width: 160px;
        height: 60px;
        margin-top: 0;
    }

`

export const PartnerIMG = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const PartnerTileCover = styled(LinkR)`
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    overflow: hidden;
`