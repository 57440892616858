import React from 'react';
import { PressContainer, TitleWrapper, Title, PressWrapper, C1, C2, ReleaseWrapper, Header, Subheader, Button } from './IndustryElements';

const IndustrySection = ({ 
    title,
    indHeader1,
        indArticle1, indButton1, indLink1,
        indArticle2, indButton2, indLink2,
    indHeader2,
        indArticle3, indButton3, indLink3,
    indHeader3,
        indArticle4, indButton4, indLink4,
    indHeader4,
        indArticle5, indButton5, indLink5,
    indHeader5,
        indArticle6, indButton6, indLink6,
        indArticle7, indButton7, indLink7,
    indHeader6,
        indArticle8, indButton8, indLink8,
        indArticle9, indButton9, indLink9,
        indArticle10, indButton10, indLink10,
        indArticle11, indButton11, indLink11,
        indArticle12, indButton12, indLink12,
        indArticle13, indButton13, indLink13,
        indArticle14, indButton14, indLink14,
        indArticle15, indButton15, indLink15,

}) => {
    
    return (
        <>
            <PressContainer>
                <TitleWrapper>
                    <Title>
                        {title}
                    </Title>
                </TitleWrapper>
                <PressWrapper>
                    <C1>
                        <ReleaseWrapper>
                            <Header>{indHeader1}</Header>
                            <Subheader>{indArticle1}<Button to={indLink1} target='_blank'>{indButton1}</Button></Subheader>
                            <Subheader>{indArticle2}<Button to={indLink2} target='_blank'>{indButton2}</Button></Subheader>
                        </ReleaseWrapper>
                        <ReleaseWrapper>
                            <Header>{indHeader2}</Header>
                            <Subheader>{indArticle3}<Button to={indLink3} target='_blank'>{indButton3}</Button></Subheader>
                        </ReleaseWrapper>
                        <ReleaseWrapper>
                            <Header>{indHeader3}</Header>
                            <Subheader>{indArticle4}<Button to={indLink4} target='_blank'>{indButton4}</Button></Subheader>
                        </ReleaseWrapper>
                        <ReleaseWrapper>
                            <Header>{indHeader4}</Header>
                            <Subheader>{indArticle5}<Button to={indLink5} target='_blank'>{indButton5}</Button></Subheader>
                        </ReleaseWrapper>
                        {/* <ReleaseWrapper>
                            <Header>{indHeader5}</Header>
                            <Subheader>{indArticle6}<Button to={indLink6} target='_blank'>{indButton6}</Button></Subheader>
                            <Subheader>{indArticle7}<Button to={indLink7} target='_blank'>{indButton7}</Button></Subheader>
                        </ReleaseWrapper> */}
                    </C1>
                    <C2>
                        <ReleaseWrapper>
                            <Header>{indHeader6}</Header>
                            <Subheader>{indArticle8}<Button to={indLink8} target='_blank'>{indButton8}</Button></Subheader>
                            <Subheader>{indArticle9}<Button to={indLink9} target='_blank'>{indButton9}</Button></Subheader>
                            <Subheader>{indArticle10}<Button to={indLink10} target='_blank'>{indButton10}</Button></Subheader>
                            <Subheader>{indArticle11}<Button to={indLink11} target='_blank'>{indButton11}</Button></Subheader>
                            <Subheader>{indArticle12}<Button to={indLink12} target='_blank'>{indButton12}</Button></Subheader>
                            <Subheader>{indArticle13}<Button to={indLink13} target='_blank'>{indButton13}</Button></Subheader>
                            <Subheader>{indArticle14}<Button to={indLink14} target='_blank'>{indButton14}</Button></Subheader>
                            <Subheader>{indArticle15}<Button to={indLink15} target='_blank'>{indButton15}</Button></Subheader>
                        </ReleaseWrapper>
                        <ReleaseWrapper>
                            <Header>{indHeader5}</Header>
                            <Subheader>{indArticle6}<Button to={indLink6} target='_blank'>{indButton6}</Button></Subheader>
                            <Subheader>{indArticle7}<Button to={indLink7} target='_blank'>{indButton7}</Button></Subheader>
                        </ReleaseWrapper>
                    </C2>
                </PressWrapper>
            </PressContainer>
        </>
    )
}

export default IndustrySection;