export const industryObjOne = {
    title: "Industry News",

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader1: "Enterprise Market",


    indArticle1: "- Enterprises and 5G FWA Demand... ",
    indButton1: "Read More",
    indLink1: "https://www.sdxcentral.com/articles/analysis/are-enterprises-still-fixated-on-5g-fwa/2024/07/",

    indArticle2: "- Oracle & AT&T for Enterprise... ",
    indButton2: "Read More",
    indLink2: "https://www.telecompetitor.com/oracle-partners-with-att-for-enterprise-communications-platform/",

    //////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader2: "T-Mobile Buying up Fiber Nets",


    indArticle3: "- T-Mobiles Fiber Ambitions... ",
    indButton3: "Read More",
    indLink3: "https://www.fierce-network.com/wireless/t-mobiles-fiber-ambitions-are-pretty-much-all-anyone-cares-about-right-now",

    //////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader3: "EU",


    indArticle4: "- UK Buildings Connectivity... ",
    indButton4: "Read More",
    indLink4: "https://www.telecomtv.com/content/5g/5g-powered-public-buildings-could-save-uk-taxpayers-580m-50942/",

    //////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader4: "FWA's Getting into Fiber",

    
    indArticle5: "- Some WISPs See Opportunity in Fiber Business... ",
    indButton5: "Read More",
    indLink5: "https://www.fierce-network.com/broadband/fiber-and-fixed-wireless-peanut-butter-and-chocolate",

    //////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader5: "Latency has Value",


    indArticle6: "- Lower Latency to Find New Revenue... ",
    indButton6: "Read More",
    indLink6: "https://r.smartbrief.com/resp/snAOCGscgMDCzJujCigyoDCicNOcbG?format=multipart",

    indArticle7: "- Gaming... ",
    indButton7: "Read More",
    indLink7: "https://www.capacitymedia.com/article/2dl886xkfrxkyh76l36dc/industry-voices/on-the-edge-of-a-gaming-revolution",

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    indHeader6: "US Broadband",


    indArticle8: "- NTIA Aims to Ensure 90% of BEAD Gear is Made in USA... ",
    indButton8: "Read More",
    indLink8: "https://r.smartbrief.com/resp/snAOCGscgMDCzJuaCigyoDCicNlMUG?format=multipart",

    indArticle9: "- BEAD More Flexible Rules... ",
    indButton9: "Read More... ",
    indLink9: "https://www.fierce-network.com/broadband/unlicensed-spectrum-could-offer-wisps-recipe-success",

    indArticle10: "- Rural Broadband Protection Act... ",
    indButton10: "Read More",
    indLink10: "https://r.smartbrief.com/resp/snkpCGscgMDCyphbCigyoDCicNXPcQ?format=multipart",

    indArticle11: "- Bill Aims to Speed Up Broadband Permitting on Federal Land... ",
    indButton11: "Read More",
    indLink11: "https://r.smartbrief.com/resp/snkpCGscgMDCyphcCigyoDCicNfxIT?format=multipart",

    indArticle12: "- Defining 'Middle Mile' is Important to BEAD Connectivity... ",
    indButton12: "Read More",
    indLink12: "https://www.fierce-network.com/broadband/industry-struggles-define-middle-mile-even-though-its-critical-bead",

    indArticle13: "- Va. is Set to Apply for $1.48B in Federal Broadband Funds... ",
    indButton13: "Read More",
    indLink13: "https://r.smartbrief.com/resp/snkpCGscgMDCyphfCigyoDCicNKRpg?format=multipart",

    indArticle14: "- Comcast and Others Recommended for CA Broadband Grants... ",
    indButton14: "Read More",
    indLink14: "https://www.telecompetitor.com/comcast-six-others-recommended-for-91m-in-california-broadband-grants/",

    indArticle15: "- Ericsson Partners with Electric Co-ops... ",
    indButton15: "Read More",
    indLink15: "https://www.fierce-network.com/wireless/ericsson-partners-electric-co-op-private-network-push",



}