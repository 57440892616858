import React from 'react';
import { SiX, SiYoutube, SiReddit, SiLinkedin, SiInstagram } from 'react-icons/si';
import { animateScroll as scroll} from 'react-scroll';
import { FooterContainer, FooterWrap, FooterIMG, FooterLogo, Slogan, TextH2, ContactWrapper, ContactLink, Legal, LegalLink, SocialIcons, SocialIconLink } from './FooterElements';

const Footer = ({ img1, alt1, pp, tac }) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <FooterLogo to='/' onClick={toggleHome}><FooterIMG src={img1} alt={alt1}/></FooterLogo>
                    <Slogan>World's Only Carrier-Grade Optical Wireless Communications&trade;</Slogan>
                    <ContactWrapper>
                        <ContactLink to='/contact' onClick={toggleHome}>Contact Us</ContactLink>
                    </ContactWrapper>
                    <Legal>
                        <TextH2><LegalLink to={pp} target="_blank">Privacy Policy</LegalLink> | <LegalLink to={tac} target="_blank">Terms of Use</LegalLink></TextH2>
                        <TextH2>Copyright &copy; 2024 Attochron, LLC. All rights reserved.</TextH2>
                    </Legal>
                    <SocialIcons>
                        <SocialIconLink href="//www.linkedin.com/company/attochron-llc/" target="_blank" aria-label="Linkedin">
                            <SiLinkedin />
                        </SocialIconLink>
                        <SocialIconLink href="//twitter.com/attochron1" target="_blank" aria-label="Twitter">
                            <SiX />
                        </SocialIconLink>
                        <SocialIconLink href="//www.youtube.com/@attochronmain2346" target="_blank" aria-label="Youtube">
                            <SiYoutube />
                        </SocialIconLink>
                        <SocialIconLink href="//www.reddit.com/user/Attochron/" target="_blank" aria-label="Reddit">
                            <SiReddit />
                        </SocialIconLink>
                        <SocialIconLink href="//www.instagram.com/attochronllc/" target="_blank" aria-label="Instagram">
                            <SiInstagram />
                        </SocialIconLink>
                    </SocialIcons>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer;