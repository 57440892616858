import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import PressSection from '../components/PressSection';
import Footer from '../components/Footer';
import { sideObjOne } from '../components/Sidebar/Data';
import { navObjOne } from '../components/Navbar/Data';
import { pressObjOne } from '../components/PressSection/Data';
import { footerObjOne } from '../components/Footer/Data';

const News = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | News";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} {...sideObjOne}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <PressSection {...pressObjOne} />
            <Footer {...footerObjOne}/>
        </>
    )
}

export default News