import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#d3d3d3;' : 'transparent')};
    height: 100px;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 5;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100px;
    z-index: 1;
    width: 100%;
    max-width: 1400px;
    text-decoration: none;
`

export const NavLogo = styled(LinkR)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    text-decoration: none;
    display: flex;

    @media screen and (max-width: 480px) {
        left: 1rem;
        top: .7rem;
        transform: none;
    }
`

export const NavTextWrapper = styled.div`
    display: flex;
    max-width: 380px;
    text-align: center;
    margin-left: 1rem;

`

export const NavText = styled.h1`
    color: #000000;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;

    @media screen and (max-width: 1025px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 769px) {
        display: none;
    }
`

export const NavImg = styled.img`
    height: 85px;
    width: auto;

    @media screen and (max-width: 480px) {
        height: 70px;
    }
`

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 769px) {
		display: flex;
		position: absolute;
		top: 1.5rem;
		right: 1rem;
        background-color: transparent;
        border: 2px solid #000000;
        border-radius: 10px;
        padding: 8px 8px 8px 8px;
		cursor: pointer;
	}
`

export const OpenIcon = styled(FaBars)`
    color: #000000;
    font-size: 1.2rem;
    margin-top: 0.15rem;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`

export const IconText = styled.h1`
    color: #000000;
    font-size: 1.2rem;
    margin-right: 0.4rem;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`

export const NavMenu = styled.ul`
    position: relative;
    margin-right: 1 rem;
    display: flex;
    algin-items: center;
    list-style: none;
    text-align: center;
    text-decoration: none;

    @media screen and (max-width: 769px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 100px;
`

export const NavLink = styled(LinkR)`
    color: #000000;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    padding-left: 2rem;
    right: 1rem;
    height: 100%;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }

    @media screen and (max-width: 1025px) {
        ${'' /* font-size: 1rem; */}
        padding-left: 1.2rem;
    }
`

export const DropDownItem = styled.div`
    color: #000000;
    display: flex;
    text-align: center;
    text-decoration: none;
    position: relative;
    padding-left: 2rem;
    right: 1rem;
    height: 100%;
    top: 40px;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    @media screen and (max-width: 1025px) {
        ${'' /* font-size: 1rem; */}
        padding-left: 1.2rem;
    }
`

export const DropDownItemL = styled(LinkR)`
    color: #000000;
    display: flex;
    text-align: center;
    text-decoration: none;
    position: relative;
    padding-left: 2rem;
    right: 1rem;
    height: 100%;
    top: 40px;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    @media screen and (max-width: 1025px) {
        ${'' /* font-size: 1rem; */}
        padding-left: 1.2rem;
    }
`

export const DropDownContent = styled.div`
    display: none;
    position: absolute;
    min-width: 160px;
    ${'' /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */}
    z-index: 1;
`

export const DropDownList = styled.li`
    height: 70px;

    &:hover {
        text-decoration: underline;
    }

    &:hover ${DropDownContent} {
        display: block;
    }
`

export const DropDownLink = styled(LinkR)`
    color: #000000;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;

    }
`