import React from 'react';
import { TechContainer, TitleWrapper, Title, SectionWrapper, SectionTitleWrapper, SectionTitle, SubsectionWrapper, Header, Subheader, ButtonS1, ButtonS2, InnerLINK} from './TechElements';

const TechSection = ({
    target,
    title1, title2, title3,
    header1, header2, header3, header4,
    link1, link2, link3,
    title6, header6, subheader6, button6, link6,

}) => {

    return (
        <>
            <TechContainer>
                <TitleWrapper>
                    <Title>
                        {title1}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SectionTitleWrapper>
                        <SectionTitle>{header1}</SectionTitle>
                    </SectionTitleWrapper>
                    <SubsectionWrapper>
                        <Subheader>Attochron develops, licenses and sells next-generation <i>free-space optical communications</i> (FSOC) technologies. ‘Free-space optical’ means that, like radio signals, a laser is <i>beamed through the air, or through Space itself, to carry data communications</i>. FSOC allows the high speeds of fiber-optics with the convenience of wireless systems. Additionally, the FSOC wireless spectrum is safe for living creatures and is not governed by federal governments, making FSOC deployment rapid and low-cost. </Subheader>
                    </SubsectionWrapper>
                    <SectionTitleWrapper>
                        <SectionTitle>{header2}</SectionTitle>
                    </SectionTitleWrapper>
                    <SubsectionWrapper>
                        <Subheader><i>However</i>, the technical challenge for FSOC has always been a combination of maintaining a quality laser communications signal (with no errors) and staying connected in weather (signal connection aka ‘availability’). These problems arose because most lasers <i>interfere with themselves</i> while travelling through air. Attochron solved  this problem by using <i>short coherence length</i> (SCL) lasers – a type of laser never used for FSOC in the past. SCL’s don’t ‘self-interfere’ and thus maintain an excellent signal quality and have the added benefit of improving signal range in various weather conditions. Attochron went on to prove the <strong>‘World’s Only Carrier-Grade Optical Wireless Communications™’</strong> with Tier 1 carriers and Fortune 200 global enterprises.</Subheader>
                    </SubsectionWrapper>
                    <SectionTitleWrapper>
                        <SectionTitle>{header3}</SectionTitle>
                    </SectionTitleWrapper>
                    <SubsectionWrapper>
                        <Subheader>Telecommunications carriers will use Attochron FSOC for <i>1+ mile (1.6+ kilometers)</i> ‘links’. This 1-mile distance connection is the largest challenge/opportunity in the <i><u>commercial</u></i> telecommunications network market today. Why? Fiber-optics comes <i><u>within a mile</u></i> of most every possible <i><u>commercial</u></i> customer (enterprises, cell towers, multi-dwelling units, new 5G cell sites, etc.)…but only directly connects to 25% of them. <i><u>Commercial</u></i> fiber-optic connections (where only one customer is using this entire fiber) are too expensive to ‘build’ the short distance to these millions of <i><u>commercial</u></i> customers. And radio systems for this <i>application</i> have reached their distance/data capacity limits. </Subheader>
                    </SubsectionWrapper>
                    <SectionTitleWrapper>
                        <SectionTitle>{header4}</SectionTitle>
                    </SectionTitleWrapper>
                    <SubsectionWrapper>
                        <Subheader>The result is that 75% of commercial buildings (<i><u>millions</u></i>), 50% of existing cell towers (<i><u>1+ million</u></i>) and most multi-dwelling units (<i><u>millions</u></i>) don’t have the fast data speeds they need. The decision to <i>not deploy <u>countless</u></i> 5G cell sites was the result of no <i><u>commercial</u></i> fiber-optic links to connect them to the nearby fiber-optics. Now, with the debut of AI, these data speeds are more necessary and demanding than ever. <strong>Attochron’s ALTIS-7™</strong> FSOC link will serve the <i><u>commercial last mile</u></i> market, complementing the existing fiber-optic backbone and radio distribution networks for carriers everywhere. </Subheader>
                    </SubsectionWrapper>
                </SectionWrapper>
                <TitleWrapper>
                    <Title>
                        {title2}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SubsectionWrapper>
                        <Subheader>We suggest beginning with the <InnerLINK to={link1} target={target}>May 2024 white paper “Free-space Optical Communications with Broadband Optical Sources”</InnerLINK> where you will learn about Attochron’s <i><strong>physics breakthroughs for FSOC using short coherence length (SCL) light signals</strong></i>. Scientists may like the deeper dive in the <InnerLINK to={link2} target={target}>October 2023 SPIE technical paper <strong>“Atmospheric propagation of femtosecond optical pulses: Gaussian beamlet model of coherence effects”</strong></InnerLINK>. Attochron’s <InnerLINK to={link3} target={target}>September 2024 paper “Fundamental advantages of Ultrashort Optical Pulses for Free-Space Optical Communications”</InnerLINK> discusses one of the key benefits of using ultrashort pulse lasers in FSOC that will extract another 5 or 6 decibels (dB) of link margin in any biased photodetector. Originally proven by Bell-Laboratories for fiber-optic systems Attochron was the first to witness this in FSOC.</Subheader>
                    </SubsectionWrapper>
                </SectionWrapper>
                <TitleWrapper>
                    <Title>
                        {title3}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SubsectionWrapper>
                        <Subheader>Attochron has hundreds of <strong>Patents and Patents-Pending</strong> covering these technologies worldwide. This patent list will be published soon but can be found online searching the U.S. Patent & Trademark Office and the <InnerLINK to='https://patentscope.wipo.int/search/en/result.jsf?_vid=P20-M3X9M0-17000' target={target}>World Intellectual Patent Organization (WIPO)</InnerLINK>, etc.. A partially complete list is online in <InnerLINK to='https://patents.justia.com/assignee/attochron-llc' target={target}>Justia’s list of Attochron’s patents</InnerLINK>.</Subheader>
                    </SubsectionWrapper>
                </SectionWrapper>
                <TitleWrapper>
                    <Title>
                        {title6}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SubsectionWrapper>
                        <Header>{header6}<ButtonS1 to={link6} target={target}>{button6}</ButtonS1></Header>
                        <Subheader>{subheader6}</Subheader>
                    </SubsectionWrapper>
                </SectionWrapper>
            </TechContainer>
        </>
    )
}

export default TechSection;