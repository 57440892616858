import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Team from '../components/TeamSection';
import Footer from '../components/Footer';
import { navObjOne } from '../components/Navbar/Data';
import { teamObjOne } from '../components/TeamSection/Data';
import { footerObjOne } from '../components/Footer/Data';

const About = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | About Us";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <Team {...teamObjOne} />
            <Footer {...footerObjOne}/>
        </>
    )
}

export default About