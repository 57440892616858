import React from 'react';
import { StoryContainer, StoryWrapper, StoryHeader, StorySubheader, StoryTextContainer, StoryText } from './StoryElements';

const StorySection = ({ storyHeader, storySubheader, storyText1, storyText2 }) => {
    
    return (
        <>
            <StoryContainer>
                <StoryWrapper>
                    <StoryHeader>{storyHeader}</StoryHeader>
                    <StorySubheader>{storySubheader}</StorySubheader>
                    <StoryTextContainer>
                        <StoryText>
                                    Led by technology visionary Tom Chaffee, Founder & CEO, and supported by an amazing team of all ages, 
                                    Attochron is the world-exclusive developer of Electro-Optical and Photonic technologies using <i>short 
                                    coherence length</i> (SCL) optical sources (including ultrashort pulsed lasers 'USPL'). Attochron applies
                                    the SCL technology to free-space (wireless) optical communications (FSOC), remote sensing, ranging (LIDAR)
                                    and power-beaming. An Attochron FSOC product is being developed now in response to real-world 
                                    proofs of concept (POC) Attochron performed with Tier 1 US carrier Lumen Technologies and a Fortune 10 
                                    global retailer, proving at once multiple use cases including enterprise access, backhaul, fronthaul, 
                                    middle mile, fiber backup, disaster recovery and others.
                        </StoryText>
                    </StoryTextContainer>
                    <StoryTextContainer>    
                        <StoryText>
                                    Attochron's FSOC technologies have specifically demonstrated the positive aspects of optical communication 
                                    (high speed, low latency, robust security) while overcoming &mdash; for the first time in history &mdash; the key 
                                    obstacles facing legacy FSO technologies &mdash; in particular, mildly heavy water aerosols (fog, clouds) and the 
                                    most common weather challenge: clear air scintillation effects. Attochron is Series A private equity funded 
                                    and focuses on real-world field testing, technology development and constantly improving a massive global 
                                    patent position while building Prototype pre-production links for distribution to stakeholders in the 
                                    telecom, government, and aerospace sectors for evaluation and purchase.
                        </StoryText>
                    </StoryTextContainer>
                </StoryWrapper>
            </StoryContainer>
        </>
    )
}

export default StorySection;