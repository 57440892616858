import styled from 'styled-components';

export const StoryContainer = styled.div`
    color: #000000;
    background: #d3d3d3;
	height: 100vh;
	padding-top: 10rem;
	padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;

    @media screen and (min-height: 1200px) {
        height: 75vh;
    }

    @media screen and (max-height: 880px) {
        height: 125vh;
    }

    @media screen and (max-height: 700px) {
        height: 150vh;
    }

    @media screen and (max-height: 580px) {
        height: 200vh;
    }

    @media screen and (max-height: 435px) {
        height: 300vh;
    }

    @media screen and (max-width: 480px) {
        padding-top: 8rem;
        height: 150vh;
        padding-bottom: 0;
    }

    
`

export const StoryWrapper = styled.div`
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1200px;
    justify-content: center
`

export const StoryHeader = styled.h1`
    display: flex;
	z-index: 1;
	max-width: 220px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 2.3rem;
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 2px solid #000000;

	@media screen and (max-width: 480px) {
		font-size: 1.8rem;
		text-align: center;
        max-width: 180px;
	}
`

export const StorySubheader = styled.h1`
    display: flex;
	width: 100%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 4rem;
    max-width: 800px;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	font-weight: bold;
    text-align: center;

    @media screen and (max-width: 769px) {
        padding-top: 1rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.2rem;
    }
`

export const StoryTextContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 1rem;
`

// export const StoryTextWrapper = styled.div`
//     display: grid;
//     width: 100%;
//     max-width: 1000px;
//     margin: 0 auto;
//     justify-content: center;
//     grid-template-columns: 1fr 1fr;
//     grid-template-rows: auto;

//     @media screen and (max-width: 769px) {
//         padding-left: 2rem;
//         padding-right: 2rem;
//     }

//     @media screen and (max-width: 480px) {
//         grid-template-columns: 1fr;
//     }
// `

// export const StoryR1C1 = styled.div`
//     grid-column-start: 1;
//     grid-column-end: 1;
//     max-width: 450px;
//     margin: 0 auto;

//     @media screen and (max-width: 769px) {
//         padding-right: 1rem;
//     }

//     @media screen and (max-width: 480px) {
//         padding: 0;
//     }
// `

// export const StoryR1C2 = styled.div`
//     grid-column-start: 2;
//     grid-column-end: 2;
//     max-width: 450px;
//     margin: 0 auto;

//     @media screen and (max-width: 769px) {
//         padding-left: 1rem;
//     }

//     @media screen and (max-width: 480px) {
//         grid-column-start: 1;
//         grid-column-end: 1;
//         padding: 0;
//     }
// `

export const StoryText = styled.p`
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom: 2rem;
    text-align: justify;
    ${'' /* text-justify: distribute; */}
    hyphens: auto;
    ${'' /* word-spacing: -2px; */}
    text-indent: 2rem;

    @media screen and (max-width: 769px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`