import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import IndustrySection from '../components/IndustrySection';
import Footer from '../components/Footer';
import { sideObjOne } from '../components/Sidebar/Data';
import { navObjOne } from '../components/Navbar/Data';
import { industryObjOne } from '../components/IndustrySection/Data';
import { footerObjOne } from '../components/Footer/Data';

const Industry = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | Industry News";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} {...sideObjOne}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <IndustrySection {...industryObjOne} />
            <Footer {...footerObjOne}/>
        </>
    )
}

export default Industry