import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import './form.css'
import { ContactContainer, ContactWrapper, ContactHeading, FormInput, FormTextarea, ButtonDiv } from './ContactElements'

const ContactSection = () => {
	const form = useRef()

	const sendEmail = (e) => {
	    e.preventDefault();

	    emailjs.sendForm('service_dhecmcp', 'template_eht8mgy', form.current, 'fDlMrsqLFZSi6gIDB')
	      .then((result) => {
	          console.log(result.text);
	      }, (error) => {
	          console.log(error.text);
	      });
	      e.target.reset();
	  };

	return (
		<>
			<ContactContainer id="contact">
				<ContactWrapper>
					<ContactHeading>Contact Us</ContactHeading>
					<form ref={form} onSubmit={sendEmail}>
						<FormInput>
							<input type="text" placeholder="First Name*" name="firstName" required/>
						</FormInput>
						<FormInput>
							<input type="text" placeholder="Last Name*" name="lastName" required />
						</FormInput>
						<FormInput>
							<input type="email" placeholder="Email*" name="email" required />
						</FormInput>
						<FormInput>
							<input type="text" placeholder="Company" name="company" />
						</FormInput>
						<FormTextarea>
							<textarea name="message" placeholder="Message*"></textarea>
						</FormTextarea>
						<ButtonDiv>
							<input class="button" type="submit" value="Submit" />
						</ButtonDiv>
					</form>
				</ContactWrapper>
			</ContactContainer>
		</>
	)
}

export default ContactSection