import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import { sideObjOne } from '../components/Sidebar/Data';
import { navObjOne } from '../components/Navbar/Data';
import { heroObjOne } from '../components/HeroSection/Data';
import { footerObjOne } from '../components/Footer/Data';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | World's Only Carrier-Grade OWC";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} {...sideObjOne}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <HeroSection {...heroObjOne}/>
            <Footer {...footerObjOne}/>
        </>
    )
}

export default Home