import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import StorySection from '../components/StorySection';
import Footer from '../components/Footer';
import { navObjOne } from '../components/Navbar/Data';
import { storyObjOne } from '../components/StorySection/Data';
import { footerObjOne } from '../components/Footer/Data';

const Story = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | Our Story";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <StorySection {...storyObjOne} />
            <Footer {...footerObjOne}/>
        </>
    )
}

export default Story