export const teamObjOne = {
    storyHeader: "Our Story",
    storySubheader: 'Attochron is Developing the Next Generation of "Light Waves" for Free Space Optical Communications.',

    teamTitle: "Our Team",

    alt: require('../../images/blankPerson.png'),
    brianG: require('../../images/brianGregory.jpg'), brianG_LI: "https://www.linkedin.com/in/brian-gregory-454501177/", brianG_Name: "Brian Gregory", brianG_Title: "Lead Electrical & Software Engineer",
    carterW: require('../../images/carterWinfield.jpg'), carterW_LI: "https://www.linkedin.com/in/carter-winfield-4279b91a3/", carterW_Name: "Carter Winfield", carterW_Title: "Software & Computer Engineer", 
    garrettO: require('../../images/garrettOlson.png'), garrettO_LI: "https://www.linkedin.com/in/garrett-olson-510633161/", garrettO_Name: "Garrett Olson", garrettO_Title: "Software & Network Engineer",
    jimO: require('../../images/jimOlson.png'), jimO_LI: "https://www.linkedin.com/in/jamesmichaelolson/", jimO_Name: "Jim Olson", jimO_Title: "Sr. VP Sales & Marketing",
    perryS: require('../../images/perrySiehien.png'), perryS_LI: "https://www.linkedin.com/in/perry-siehien-06844585/", perryS_Name: "Perry Siehien", perryS_Title: "Data Analyst & Software Engineer",
    seanC: require('../../images/seanCarrington.jpg'), seanC_LI: "https://www.linkedin.com/in/sean-carrington-46603b207/", seanC_Name: "Sean Carrington", seanC_Title: "Director of Facilities & Logistics",
    tazC: require('../../images/tazColangelo.png'), tazC_LI: "https://www.linkedin.com/in/taz-colangelo-5a0275140/", tazC_Name: "Taz Colangelo", tazC_Title: "Lead Optics & Photonics Engineer",
    tomC: require('../../images/tomChaffee.png'), tomC_LI: "https://www.linkedin.com/in/tomchaffee/", tomC_Name: "Tom Chaffee", tomC_Title: "Founder, CEO & Managing Member",
    nickV: require('../../images/nickV.png'), nickV_LI: "https://www.linkedin.com/in/nickvohra1/", nickV_Name: "Nick Vohra", nickV_Title: "Product Development Specialist",
    willT: require('../../images/willTillery2.jpg'), willT_LI: "https://www.linkedin.com/in/will-tillery-6ab8051b/", willT_Name: "Will Tillery", willT_Title: "Operations",

    consTitle: "Our Consultants",

    whk: require('../../images/wayneKnox.png'), whk_LI: "https://www.linkedin.com/in/wayne-knox-1505812/", whk_Name: "Dr. Wayne Knox", whk_Title: "Optical Physics and Telecommunications Engineering",
    patC: require('../../images/patrickCaputo.png'), patC_LI: "https://www.linkedin.com/in/patrick-caputo-b7796749/", patC_Name: "Patrick Caputo", patC_Title: "Electrical and Photonics Engineer",
    eclipse: require('../../images/eclipse.png'), eclipse_LI: "https://www.linkedin.com/company/eclipse-optics/", eclipse_Name: "Eclipse Optics", eclipse_Title: "Optical Design and Sensing",
    davidS: require('../../images/davidSchenck.jpg'), davidS_LI: "/about", davidS_Name: "Dr. David Schenk", davidS_Title: "Principal Engineer",
    jimM: require('../../images/jimMcBride.jpg'), jimM_LI: "/about", jimM_Name: "Jim McBride", jimM_Title: "Optical and Mechanical Engineer",

    investTitle: "Lead Investor",

    tcr: require('../../images/tcrLogo.jpg'), tcr_LI: "https://www.tcr-ny.com/", tcr_Name: "Three Cities Research, Inc.", tcr_Title: "Private Equity Firm",

    legalTitle: "Legal Counsel",

    alexS: require('../../images/alexS.png'), alexS_LI: "https://www.linkedin.com/in/alexander-slafkosky/", alexS_Name: "Alex Slafkosky", alexS_Title: "Technologist and IP Attorney at Scale LLP",
    ericB: require('../../images/ericBlatt.png'), ericB_LI: "https://www.linkedin.com/in/eric-d-blatt/", ericB_Name: "Eric Blatt", ericB_Title: "Technologist and IP Attorney at Scale LLP",

    advisorTitle: "Advisory Board",

    robF: require('../../images/robF.png'), robF_LI: "https://www.linkedin.com/in/rob-fleishauer-6754639/", robF_Name: "Dr. Rob Fleishauer", robF_Name2: "Lt. COL USAF (Ret.)", robF_Title: "Atmospheric Scientist",
    johnM: require('../../images/johnMurray.png'), johnM_LI: "https://www.linkedin.com/in/john-j-murray-iii-66b7843/", johnM_Name: "John Murray", johnM_Title: "Marketing, sales, content development, training, corporate cirriculumns, CRM development",
    tomF: require('../../images/tomF.png'), tomF_LI: "https://www.linkedin.com/in/xlightsystems/", tomF_Name: "Thomas Farrell", tomF_Title: "DSP systems, cameras, laser beam control and FSOC systems",
    tomB: require('../../images/tomBarnett.png'), tomB_LI: "https://www.linkedin.com/in/tom-barnett-3ba63411/", tomB_Name: "Tom Barnett", tomB_Title: "Director of Strategic Innovation at Lumen Technologies",
    janisV: require('../../images/janisV.png'), janisV_LI: "https://www.linkedin.com/in/janis-a-valdmanis-91ba9a7", janisV_Name: "Dr. Janis Valdmanis", janisV_Title: "General Manager at Thorlabs-UFO",
    jeffS: require('../../images/jeffSmith.jpg'), jeffS_LI: "https://sma-alumni.org/wp-content/uploads/2017/03/jgs-72.pdf", jeffS_Name: "Dr. Jeffrey Smith", jeffS_Name2: "Brig. GEN US ARMY (Ret.)", jeffS_Title: "Strategy & Federal Telecommunication Systems",

    partnerTitle: "Our Partners",

    thorlabs: require('../../images/thorlabsT.png'), thorlabs_link: "https://www.thorlabs.com/",
    ott: require('../../images/ottT.png'), ott_link: "https://www.otthydromet.com/en/",
    lumen: require('../../images/lumenT.png'), lumen_link: "https://www.lumen.com/en-us/home.html",
    nuphoton: require('../../images/nuphoton.png'), nuphoton_link: "https://nuphoton.com/",
    droplet: require('../../images/dropletT.png'), droplet_link: "https://www.dropletmeasurement.com/",
    keysight: require('../../images/keysightT.png'), keysight_link: "https://www.keysight.com/us/en/home.html",
    viavi: require('../../images/viaviT.png'), viavi_link: "https://www.viavisolutions.com/en-us",
    conclusive: require('../../images/conclusiveT.png'), conclusive_link: "https://conclusive.tech/",

}