import styled from 'styled-components'

export const ContactContainer = styled.div`
	color: #000000;
    background: #d3d3d3;
	height: 75vh;
	padding-top: 10rem;
	padding-bottom: 2rem;

	@media screen and (max-width: 768px) {
		padding-top: 8rem;
	}

	@media screen and (max-height: 1070px) {
		height: 90vh;
	}

	@media screen and (max-height: 890px) {
		height: 115vh;
	}

	@media screen and (max-height: 700px) {
		height: 150vh;
	}

	@media screen and (max-height: 532px) {
		height: 200vh;
	}

	@media screen and (max-height: 402px) {
		height: 275vh;
	}
	
`

export const ContactWrapper = styled.div`
	display: grid;
	z-index: 1;
	height: auto;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
`

export const ContactHeading = styled.h1`
	display: flex;
	z-index: 1;
	max-width: 220px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 2px solid #000000;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 160px;
	}
`

export const ContactForm = styled.div`
	height: auto;
	width: 100%;
	max-width: 1100px;
	transform: translate(-50%, 0%);
	position: relative;
	right: 50%;
	border-radius: 10px;
	left: 50%;
	justify-content: center;
	align-items: center;
`

export const FormInput = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;
	max-width: 1100px;
`

export const FormTextarea = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;
	max-width: 1100px;
`

export const ButtonDiv = styled.div`
	display: flex;	
	align-items: center;
	justify-content: center;
`