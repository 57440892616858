import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import TechSection from '../components/TechSection';
import Footer from '../components/Footer';
import { sideObjOne } from '../components/Sidebar/Data';
import { navObjOne } from '../components/Navbar/Data';
import { techObjOne } from '../components/TechSection/Data';
import { footerObjOne } from '../components/Footer/Data';

const Tech = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | Technology";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} {...sideObjOne}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <TechSection {...techObjOne}/>
            <Footer {...footerObjOne}/>
        </>
    )
}

export default Tech