export const pressObjOne = {
    title: "Attochron News",
    target: "_blank",

    pressHeader3: "October 10, 2024 - CNN Article - Lasers could take broadband where fiber optics can't",
    pressSubheader3: "Virginia-based company Attochron says it's ready to launch its own version of free-space optics... ",
    pressButton3: "Read More",
    pressLink3: "https://www.cnn.com/2024/10/09/tech/lasers-fso-internet-attochron-spc/index.html",

    pressHeader2: "September 18, 2024 - FIERCE NETWORK Article - Lumen Technologies likes free space optical tech from Attochron",
    pressSubheader2: "Lumen Technologies is buying free space optical technology from Attochron and says Attochron's ultra short pulsed laser solves... ",
    pressButton2: "Read More",
    pressLink2: 'https://www.fierce-network.com/broadband/lumen-technologies-likes-free-space-optical-tech-attochron',

    pressHeader1: "July 17, 2024 - Attochron Completes $15M Series-A Funding",
    pressSubheader1: "Attochron LLC, the leader in providing carrier-grade optical wireless equipment, has closed $15M in Series A funding with a leading... ",
    pressButton1: "Read More",
    pressLink1: require('../../docs/2024 Attochron - TCR. Inc. PE Series A - Press Release - 090524.pdf'),
}