import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import { navObjOne } from '../components/Navbar/Data';
import { footerObjOne } from '../components/Footer/Data';

const Contact = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.title = "Attochron | Contact Us";
    }, []);

	const toggle = () => {
		setIsOpen(!isOpen)
	}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} {...navObjOne}/>
            <ContactSection />
            <Footer {...footerObjOne}/>
        </>
    )
}

export default Contact