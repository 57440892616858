export const techObjOne = {
    target: "_blank",

    title1: 'Executive Summary',
    header1: "What is Attochron FSOC?",
    header2: "Attochron FSOC 'Changes Everything'",
    header3: "What Problem Does Attochron FSOC Solve?",
    header4: "The Market is Millions of Connections = $$Billions",

    title2: "Attochron's Technical Papers",

    title3: "Attochron's Exclusive Worldwide Patent Position",




    link1: require('../../docs/2024 Attochron - FSOC with Broadband Optical Sources rev070924.pdf'),
    link2: 'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/12691/126910N/Atmospheric-propagation-of-femtosecond-optical-pulses--Gaussian-beamlet-model/10.1117/12.2676888.short#_=_',
    link3: require('../../docs/2024 Attochron Advantages of Impulsive Coding in FSOC-9-24-2024.pdf'),

    title6: 'Attochron Data',
    header6: "Attochron Ultrashort Pulse Laser (USPL) FSOC Data",
    subheader6: "NOTE - Bit Error Rate (BER) Data: Attochron's FSOC research and development has never used signal correction technologies like forward error correction (FEC) or channel coding. These techniques are used for controlling errors in data transmission over unreliable or noisy communication channels. Attochron's FSOC link's data rates and their bit error rate data reflect the actual performance of the FSOC links.",
    button6: " ...View USPL Data",
    link6: 'https://ln5.sync.com/dl/de84ef6b0/jbzb3s72-n8zmcnc6-c5vdr9ek-kngg4kpi',

}