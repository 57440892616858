import React, { useState, useEffect, useMemo } from 'react';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import styles from "./Carousel.css";
import { HeroContainer, SlideWrapper, SlideIMG, SlideHeader, SlideSubHeader, SlideButton, IndicatorWrapper, TextWrapper, SlideText } from './HeroElements';

const HeroSection = ({ slides }) => {
    const [slide, setSlide] = useState(0);
    const [hover, setHover] = useState(false)

    const eventHandlers = useMemo(() => ({
        onMouseOver() { setHover(true); },
        onMouseOut() { setHover(false); }
    }), []);

    useEffect(() => {
        if (hover) return;
        let interval = setInterval(() => {
            setSlide((slide) => slide === slides.length - 1 ? 0 : slide + 1);
        }, 10000);
        return () => clearInterval(interval);
    }, [slides.length, hover]);

    const nextSlide = () => {
        setSlide((slide) => slide === slides.length - 1 ? 0 : slide + 1);
    }

    const prevSlide = () => {
        setSlide((slide) => slide === 0 ? slides.length - 1 : slide - 1);
    }

    const mod = (n, m) => {
        let result = n % m;
        return result >= 0 ? result : result + m;
    }

    return (
        <>
            <HeroContainer>
                {slides.map((item, idx) => {
                    const prevS = mod(slide - 1, slides.length);
                    const nextS = mod(slide + 1, slides.length);

                    let className = "";
                    let classN = "";

                    if (idx === slide) {
                        className = "slide slide-current";
                    } else if (idx === prevS) {
                        className = "slide slide-prev";
                    } else if (idx === nextS) {
                        className = "slide slide-next";
                    } else {
                        className = "slide-hidden";
                    }

                    if (idx === 0 || idx === 3 || idx === 2) {
                        classN = "no-grad bg-free";
                    } else if (idx === 2) {
                        classN = "grad-full bg-fit";
                    } else {
                        classN = "grad-full bg-free pic-fit";
                    }

                    if (idx === 2) {
                        classN += " full-fit";
                    } else {
                        classN += " pic-fit";
                    }

                    if (idx === 3) {
                        classN += " news-fix";
                    }

                    return (
                        <SlideWrapper key={item.id} className={className} {...eventHandlers}>
                            <SlideIMG src={item.src} alt={item.alt} className={classN}/>
                            <TextWrapper>
                                <SlideText>
                                    <SlideHeader>{item.header}</SlideHeader>
                                    <SlideSubHeader>{item.subheader}</SlideSubHeader>
                                    <SlideButton to={item.link} target={item.target}>{item.button}</SlideButton>
                                </SlideText>
                            </TextWrapper>
                        </SlideWrapper>
                    )
                })}
                <IndicatorWrapper>
                    <SlArrowLeft onClick={prevSlide} className="arrow arrow-left"/>
                    {slides.map((_, idx) => {
                        return (
                            <button key={idx} className={slide === idx ? "indicator" : "indicator indicator-inactive"} onClick={() => setSlide(idx)}></button>
                        );
                    })}
                    <SlArrowRight onClick={nextSlide} className='arrow arrow-right'/>
                </IndicatorWrapper>
            </HeroContainer>
        </>
    )
}

export default HeroSection